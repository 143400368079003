/* font-family: "Inter", sans-serif; */


:root{
  --color-black: #020202;
  --color-text: #242424;
  --color-primary: #039d46;
  --color-white: #ffffffdd;
  --color-dark-blue: #091024;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@import "~react-image-gallery/styles/css/image-gallery.css";


/* global */
a{
  text-decoration: none;
}
img{
  width: 100%;
}
section{
  padding: 70px 0;
}
ul{
  list-style: none;
  display: flex;
}
p{
  margin: 0;
}
input{
  border-color: var(--color-text) !important;
}
.input{
  outline: none !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  height: 45px;
  border: none !important;
  border-bottom: 1px solid var(--color-text) !important;
}
.input:focus{
  box-shadow: none !important;
  border-color: var(--color-primary) !important;
}
.sectin-heading-group{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}
.section-intro{
  margin-bottom: 30px;
}
.btn{
  border-radius: 20px;
  font-size: 15px;
  padding: 7px 18px !important;
  transition: .3s ease-in-out;
  border-color: var(--color-black);
  font-family: "Sora", serif;
}
.btn svg{
  margin-left: 8px;
}
.btn-primary{
  background-color: var(--color-black);
  color: #fff;
}
.btn-primary:hover{
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.btn-primary-main{
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}
.btn-primary-main:hover{
  background-color: var(--color-black);
  border-color: var(--color-black);
}
.btn-outline-primary{
  color: var(--color-primary);
  border-color: var(--color-primary);
}
.btn-outline-white{
  color: white;
  border-color: white;
}
.btn-outline-white:hover{
  background-color: white;
  color: var(--color-black);
}
.hover-white:hover{
  background-color: white;
  color: var(--color-black);
}
#page-header{
  padding: 0;
  background-image: url('./assets/image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, .7);
  background-blend-mode: multiply;
  min-height: 200px;
}
#root{
  overflow: hidden;
}

/* typography */

.hero-heading{
  font-size: 38px;
  font-weight: 700;
  font-family: "Sora", serif;
}
.error{
  color: red;
}
.status{
  font-size: 12px;
  margin-top: 2px;
  font-family: "Montserrat", serif;
}
.success{
  color: var(--color-primary);
}
.counter-number{
  font-size: 44px;
  font-weight: 900;
  font-family: "Sora", serif;
}
.conter-text{
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", serif;
}

.text,.post-content p{
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: var(--color-text);
  font-family: "Montserrat", serif;
}
.primary-heading{
  font-size: 37px;
  font-weight: 700;
  color: var(--color-black);
  font-family: "Sora", serif;
}
.color-primary{
  color: var(--color-primary);
}
.secondary-heading,.post-content h2{
  font-size: 19px;
  font-weight: 700;
  color: var(--color-black);
  font-family: "Sora", serif;
}
.text-white{
  color: #fff;
  
}
.text-light{
  color: var(--color-white);
 
}
.accent-heading{
  font-size: 22px;
  font-weight: 700;
  color: var(--color-black);
  font-family: "Sora", serif;
}
.page-heading{
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  font-family: "Sora", serif;
}


/* navigation */
.nav-item{
  margin: 0 20px;
}
.nav-link{
  padding: 6px .5rem;
  border-top: 2px solid transparent;
  font-family: "Montserrat", serif;
}
.nav-link:hover,.nav-link.active{
  border-top: 2px solid var(--color-primary);
  color: var(--color-primary) !important;
  font-family: "Montserrat", serif;
  padding: 6px .5rem;
}

/* hero */
#hero{
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-image: url('./assets/image.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #00000080;
  background-blend-mode: multiply;
}
#hero .hero-des{
  margin: 40px 0;
}
.hero-form{
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  padding: 30px;
  border-radius: 20px;
}
.hero-form .accent-heading{
  margin-bottom: 30px;
}
.accent-heading button{
  margin-top: 30px;
}

/* 
auth */

.auth-wrapper{
  min-height: calc(100vh + 250px);
  display: flex;
  align-items: start;
  padding-top: 180px;
  justify-content: center;
  background-image: url('./assets/image.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #00000080;
  background-blend-mode: multiply;
  margin-bottom: -250px;

}
.otp-wrapper > div{
  justify-content: center;
}
.otp-wrapper > div > input{
  min-width: 30px;
  margin: 0 10px;
}
.booking-row{
  display: flex;
  justify-content: space-between;
  padding: 8px 4px;
  border-top: 1px solid #efefef;
}
.booking-row p{
  margin: 0 !important;
  font-size: 14px !important;
}
.policy-section ol li{
  margin-bottom: 8px;
}
.policy-section h2{
  margin-top: 50px;
  margin-bottom: 20px;
}
.booing-not-found{
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.booking-it .image-gallery-thumbnails-container{
  display: none !important;
}
.booking-it .image-gallery-image{
  border-radius: 30px !important;
}
.fullscreen .image-gallery-image{
  min-height: 60vh !important;
  object-fit: contain !important;
}
.dropdown:hover .dropdown-menu {
  display: block !important;
}
/* about us */
#about{
  background-color: var(--color-dark-blue);
}
.about-right{
  padding-left: 80px;
}
.about-right .primary-heading{
  margin-bottom: 40px;
}
.about-content{
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 3px solid #ffffff30;
}
.about-feature-item{
  width: 100%;
  height: 40px;
  border: 1px solid var(--color-white);
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-feature-item svg{
  margin-right: 10px;
}

/* solution */
.solution-item{
  box-shadow: 0px 0px 24px 0px #0000001F;
  border-radius: 20px;
  overflow: hidden;
  transition: .3s ease-in-out;
}
.solution-item img{
  height: 220px;
  object-fit: cover;
}
.solution-item:hover{
  background-color: var(--color-primary);
}
.solution-content{
  padding: 25px;
}
.solution-content .btn{
  margin-top: 20px;
}
.solution-item:hover .btn{
  background-color: white;
  color: var(--color-black);
  border-color: white;
}
/* how it works */
.how-content{
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 24px 0px #00000026;
  padding: 0 30px;
}
.how-content p{
  text-align: center;
}
.solution-content .btn-primary{
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

/* blog */
.blog-item{
  box-shadow: 0px 0px 34px 0px #00000026;
  border-radius: 30px;
  padding: 15px;
  transition: .4s ease-in-out;
}
.blog-item:hover{
  background-color: var(--color-primary);
}
.blog-content{
  padding: 12px;
}
.blog-content h2,.blog-content p{
  transition: .3s ease-in-out;
}
.blog-content p{
  margin-top: 15px;
}
.blog-image{
  margin-bottom: 10px;
}
.blog-image img{
  border-radius: 30px 30px 300px 0;
  height: 240px;
  object-fit: cover;
}
.blog-content:hover h2{
  color: #fff;
}
.blog-content:hover p{
  color: var(--color-white);
}


/* review */
.reviews{
  margin-top: 30px;
  position: relative;
}
.reviews::before{
  content: '';
  width: 400px;
  height: 100%;
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
 position: absolute;
 top: 0;
 left: 10px;
 z-index: 5;
}
.reviews::after{
  content: '';
  width: 400px;
  height: 100%;
  background: linear-gradient(-90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
 position: absolute;
 top: 0;
 right: 10px;
}
.review-item{
  border: 1px solid var(--color-primary);
  padding: 30px 30px 15px 30px;
  border-radius: 30px;
  position: relative;
}
.rev-icon{
  position: absolute;
  right: 50px;
  top: -30px;
}
.review-heading{
  font-size: 22px;
  font-weight: 700;
  color: var(--color-black);
}
.review-designation{
  font-size: 14px;
  color: var(--color-text);
  font-weight: 400;
}
.review-header{
  margin-bottom: 30px;
}
.review-footer{
  margin-top: 15px;
  display: flex;
  justify-content: end;
}
.review-footer li{
  margin-left: 18px;
  color: #FFB800;
  font-size: 18px;
}
.footer-link{
  margin: 0 15px;
}
.footer-link:hover{
  color: var(--color-primary) !important;
}
.rev-avatar{
  width: 70px !important;
}

/* footer */
.footer{
  position: relative;
}
.footer-strip{
  width: 100%;
  height: 250px;
}
.footer-bottom{
  background-color: var(--color-black);
}
.footer-bottom-row{
  width: 100%;
  justify-content: space-between;
  height: 250px;
  padding-bottom: 30px;
  align-items: end;

}
.footer-cta{
  background-image: url('./assets/bg.svg');
  background-color: var(--color-primary);
  background-blend-mode: multiply;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  max-width: 1100px;
  width: 100%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}
.footer-heading{
  font-size: 50px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.footer-cta p{
  margin: 30px 0;
  text-align: center;
}
.footer-cta .hover-white{
  margin-left: 10px;
}

/* about us page */
.about{
  padding-top: 60px;
}
.inner-image-col{
  position: relative;
  padding-right: 45px;
}
.image-color-layer{
  width: 460px;
  height: 500px;
  background-color: #ebebeb;
  position: absolute;
  top: -55px;
  left: -55px;
  z-index: -1;
}
.title{
  font-size: 48px;
  font-weight: 800;
  color: #ebebeb;
  position: absolute;
  right: -110px;
  top: 150px;
  transform: rotate(-90deg);
}
.image{
  position: relative;
  z-index: 1;
}
.image img{
  position: relative;
  width: 100%;
  display: block;
  z-index: 10;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 90%);
  height: 580px;
  object-fit: cover;
}
.color-layer-2{
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 104%;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 90%);
}
.color-layer-3{
  position: absolute;
  left: 0px;
  top: 0px;
  height: 108%;
  width: 100%;
  background-color: var(--color-primary);
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 90%);
}
.exprience{
  position: absolute;
  right: 0;
  bottom: 160px;
  z-index: 11;
  padding: 16px 22px 16px;
  border-radius: 50px 0 0 50px;
  background-color: #222;
}
.exp-inner{
  position: relative;
  text-transform: uppercase;
  padding-left: 75px;
  color: white;
}
.exp-number{
  font-size: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-weight: 800;
}
.content-inner{
  padding-left: 25px;
}
.content-inner .primary-heading{
  margin: 10px 0 30px 0;
}
.content-inner p{
  margin-top: 8px;
}
.content-inner button{
  margin-top: 20px;
}
.bg-fill-primary{
  background-color: var(--color-primary);
}
.sec-heading p,.sec-heading h1{
  text-align: center;
}
.sec-heading p{
  margin-bottom: 20px;
}
.sec-heading{
  margin-bottom: 40px;
}
.why-choose-card{
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 24px 0px #0000001F;
  position: relative;
  transition: .5s cubic-bezier(0.19, 1, 0.22, 1);
}
.why-choose-card:hover{
  transform: translateY(-10px);
}
.why-choose-card::after{
  content: '';
  width: 5px;
  height: 40px;
  background-color: var(--color-black);
  position: absolute;
  top: 0;
  right: 50px;
  transition: .3s ease;
}
.why-choose-card:hover:after{
   height: 60px;
}
.why-choose-card h2{
color: var(--color-dark-blue);
}
.dropdown-item{
  padding: .5rem 1.25rem;
}
.btn-outline-primary:hover{
  background-color: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}
.dropdown-item.active,.dropdown-item:hover{
  background-color: var(--color-primary);
  color: white;
}
.dropdown-menu{
  padding: 0;
  overflow: hidden;
}
.client,.client2{
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.client img,.client2 img{
  height: 170px;
  width: auto;
  filter: brightness(0) invert(1);
}
.why-choose-card::before{
  content: '';
  width: 5px;
  height: 60px;
  background-color: var(--color-primary);
  position: absolute;
  top: 0;
  right: 70px;
  transition: .3s ease;
}
.why-choose-card:hover:before{
  height: 40px;
}
.why-choose-card .icon{
  margin-bottom: 20px;
}
.why-choose-card .icon-inner,.mission-icon-inner{
  width: 60px;
  height: 60px;
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.why-choose-card .icon-inner svg,.mission-icon-inner svg{
  width: 30px;
  height: 30px;
}
#vision-intro{
  background-image: url('./assets/image.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-bottom: 170px;
  background-color: #020202ac;
  background-blend-mode: multiply;
}
#vision{
  padding: 0;
  margin-top: -170px;
}
.mission-col{
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 24px 0px #0000001F;
  border-radius: 20px 20px 0 0;
  border-bottom: 2px solid var(--color-primary);
}
.mision-icon{
  margin-bottom: 20px;
}
.mission-icon-inner{
  background-color: var(--color-primary);
  color: white;
}
.client:nth-child(odd){
  background-color: #ebebeb;
}
.client2:nth-child(even){
  background-color: #ebebeb;
}
.client img,.client2 img{
  transition: .3s ease;
  filter: grayscale(1);
}
.client:hover img,.client2:hover img{
  transition: .3s ease;
  filter: grayscale(0);
}
.clients{
  border-radius: 20px;
  overflow: hidden;
}


/* contact us page */
.contact-top{
  padding-bottom: 70px;
}
.contact-top .container{
  position: relative;
}
.contact-form{
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 24px 0px #0000001F;
  max-width: 600px;
  right: 0;
  top: 0;
  position: absolute;
  width: 100%;
}
.contact-form h2{
  margin-bottom: 30px;
}
.contact-top .primary-heading{
  margin: 20px 0;
}
.contact-card{
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 0px 24px 0px #0000001F;
}
.contact-card-inner{
  display: flex;
  align-items: center;
}
.contact-icon svg{
    width: 30px;
    height: 30px;
    color: white;
}
.contact-icon{
  width: 60px;
  height: 60px;
  margin-right: 30px;
  background-color: var(--color-primary);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-card-inner p{
  opacity: .3;
  font-size: 14px;
  font-weight: 900;
}
.contact-card-inner h2{
  margin-bottom: 0;
  margin-top: 8px;
}
.contact-card:last-child{
  margin-top: 50px;
}
#reach .primary-heading{
  margin-bottom: 50px;
}
#reach .container{
  max-width: 1100px;
}


/* service pages */
.service-image{
  margin-bottom: 30px;
}
.service-image img{
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
}
.service-heading{
  margin-bottom: 20px;
}
.service-highlight{
  margin: 10px 0;
  padding: 30px;
  background-color: #ebebeb;
  border-left: 2px solid var(--color-primary);
  font-size: 18px;
  line-height: 30px;
}
#services .container{
  max-width: 1100px;
}
.image-gallery-slide .image-gallery-image{
  max-height: 490px !important;
  object-fit: cover !important;
}

/* book now page */
.booking-body{
  padding: 30px;
}
.booking-wrapper{
  border: 2px solid #ebebeb;
  border-radius: 20px;
}
.bookforn{
  background-color: white;
  border-radius: 20px;
  width: 100%;
}
.image-preview{
  width: 100%;
  height: 100%;
}
.image-preview p{
  
}
.preview-box{
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.preview-box img{
  width: 120px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}
.booking-footer{
  padding: 15px 30px;
  border-top: 2px solid #ebebeb;
  display: flex; justify-content: space-between; 
  align-items: center;
}